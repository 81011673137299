import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import API_BASE_URL from './config';
import './css/AdminAIDashboard.css';

const AdminAIDashboard = () => {
  const [leaders, setLeaders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLeader, setSelectedLeader] = useState(null);
  const [formResponses, setFormResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [conversation, setConversation] = useState([]);
  const [question, setQuestion] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const resp = await axios.get(`${API_BASE_URL}/api/customers`, { withCredentials: true });
        setLeaders(resp.data);
      } catch (err) {
        setError('Failed to load leaders.');
      }
    })();
  }, []);

  // Sort leaders by readiness desc
  const filteredLeaders = useMemo(() => {
    const lowerSearch = searchTerm.toLowerCase();
    const result = leaders.filter((leader) => {
      const combined = `
        ${leader.firstName || ''} 
        ${leader.lastName || ''} 
        ${leader.email || ''} 
        ${leader.organization || ''} 
      `.toLowerCase();
      return combined.includes(lowerSearch);
    });
    // Sort so higher "completeness" (readiness) is at the top
    result.sort((a, b) => (b.completeness || 0) - (a.completeness || 0));
    return result;
  }, [leaders, searchTerm]);

  const getCompletenessColor = (value) => {
    if (value >= 60) return 'hot';
    if (value >= 45) return 'medium';
    return 'low';
  };

  const handleSelectLeader = async (leader) => {
    if (leader.completeness === 0) {
      setError('This leader has 0% readiness — no feedback data to analyze.');
      return;
    }
    setSelectedLeader(null);
    setFormResponses([]);
    setConversation([]);
    setError(null);
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/api/customer-visual-report/${leader._id}`,
        { withCredentials: true }
      );
      setSelectedLeader(data.customer);
      setFormResponses(data.formResponses);
      await askAiAnalysis(
        data.formResponses,
        "Please provide a general analysis of this leader's feedback data, highlighting any interesting trends"
      );
    } catch (err) {
      setError('Failed to retrieve leader’s 360 data.');
    } finally {
      setLoading(false);
    }
  };

  const askAiAnalysis = async (responses, questionText) => {
    if (!responses || responses.length === 0) {
      setError('No form responses found for AI analysis.');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const resp = await axios.post(`${API_BASE_URL}/api/ask-ai`, {
        messages: [
          {
            role: 'user',
            content: JSON.stringify({ data: responses, question: questionText })
          }
        ]
      }, { withCredentials: true });

      const answer = resp.data;
      setConversation((prev) => [...prev, { question: questionText, answer }]);
    } catch (err) {
      setError('Failed to get AI response.');
    } finally {
      setLoading(false);
    }
  };

  const handleAskAi = async () => {
    if (!selectedLeader || formResponses.length === 0) return;
    const q = question.trim();
    if (!q) return;
    await askAiAnalysis(formResponses, q);
    setQuestion('');
  };

  const handleClearChat = () => {
    setConversation([]);
    setError(null);
  };

  return (
    <div className="admin-ai-dashboard-container">
      <h2>AI Leadership Insights (via Customer Visual Report Data)</h2>

      <div className="ai-dashboard-layout">
        <div className="leader-selection-panel">
          <div className="search-bar-container">
            <input
              className="search-bar"
              type="text"
              placeholder="Search leaders..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <ul className="leader-list">
            {filteredLeaders.map((leader) => (
              <li
                key={leader._id}
                className={
                  selectedLeader?._id === leader._id ? 'active-leader' : ''
                }
                onClick={() => handleSelectLeader(leader)}
                title={
                  leader.completeness === 0
                    ? '0% readiness - no data to analyze'
                    : 'Click to analyze'
                }
                style={{
                  cursor: leader.completeness === 0 ? 'not-allowed' : 'pointer'
                }}
              >
                <strong>
                  {leader.firstName} {leader.lastName}
                </strong>
                <br />
                <span className="leader-email">{leader.email}</span>
                <br />
                <span className="leader-org">{leader.organization}</span>
                <div className="readiness-indicator">
                  <div
                    className={`bar ${getCompletenessColor(leader.completeness || 0)}`}
                    style={{ width: `${leader.completeness || 0}%` }}
                  ></div>
                  <span>{leader.completeness || 0}%</span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="ai-chat-panel">
          {loading && <div className="loading-overlay">Loading...</div>}
          {selectedLeader ? (
            <>
              <div className="leader-header">
                <h3>
                  Analyzing: {selectedLeader.firstName} {selectedLeader.lastName}
                </h3>
                <p>
                  <strong>Email:</strong> {selectedLeader.email}
                </p>
                <p>
                  <strong>Org:</strong> {selectedLeader.organization} |{' '}
                  <strong>Product:</strong> {selectedLeader.product}
                </p>
              </div>
              <div className="conversation-container">
                {conversation.length === 0 ? (
                  <div className="no-convo-message">
                    <p>We requested an initial analysis. More Q&A below.</p>
                  </div>
                ) : (
                  conversation.map((item, index) => (
                    <div key={index} className="chat-pair">
                      <div className="chat-question">
                        <strong>You:</strong> {item.question}
                      </div>
                      <div className="chat-answer">
                        <strong>AI:</strong>
                        <br />
                        <pre>{item.answer}</pre>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="chat-input-section">
                <textarea
                  rows={3}
                  placeholder="Ask something else about this leader's data..."
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <div className="chat-actions">
                  <button onClick={handleAskAi} disabled={loading}>
                    Ask AI
                  </button>
                  <button
                    onClick={handleClearChat}
                    disabled={loading || conversation.length === 0}
                  >
                    Clear Chat
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="no-leader-selected">
              <p>Select a leader from the list to load 360 data and begin AI analysis.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminAIDashboard;
