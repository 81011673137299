import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { useTable, useSortBy } from 'react-table';
import API_BASE_URL from './config';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import './css/CustomerMaintenance.css';

Modal.setAppElement('#root');

const CustomerMaintenance = () => {
  const [formData, setFormData] = useState({
    customerId: '',
    firstName: '',
    lastName: '',
    email: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    product: '',
    organization: '',
    sponsorFirstName: '',
    sponsorLastName: '',
    sponsorEmail: ''
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [leaders, setLeaders] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [emailStatusMessage, setEmailStatusMessage] = useState('');
  const [isSendingEmail, setIsSendingEmail] = useState({});
  const [isGeneratingPdf, setIsGeneratingPdf] = useState({});
  const [customMessages, setCustomMessages] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEmailHistory, setSelectedEmailHistory] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    fetchLeaders();
  }, []);

  const fetchProducts = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/products`);
      setProducts(response.data);
      if (response.data.length > 0 && !formData.product) {
        setFormData(prev => ({ ...prev, product: response.data[0].productName }));
      }
    } catch (error) {}
  }, [formData.product]);

  const fetchLeaders = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/customers`, { withCredentials: true });
      setLeaders(response.data);
    } catch (error) {}
  }, []);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    if (!formData.email) {
      alert('Email is required.');
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      const response = await axios.post(`${API_BASE_URL}/api/customers`, formData, {
        withCredentials: true
      });
      if (response.status === 200 || response.status === 201) {
        alert('Customer added/updated successfully.');
        fetchLeaders();
        resetForm();
      }
    } catch (error) {
      alert('Failed to add/update customer.');
    }
  };

  const resetForm = () => {
    setFormData({
      customerId: '',
      firstName: '',
      lastName: '',
      email: '',
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      product: '',
      organization: '',
      sponsorFirstName: '',
      sponsorLastName: '',
      sponsorEmail: ''
    });
    setIsEditMode(false);
  };

  const handleEditLeader = leader => {
    setIsEditMode(true);
    setFormData({
      customerId: leader._id,
      firstName: leader.firstName || '',
      lastName: leader.lastName || '',
      email: leader.email || '',
      streetAddress: leader.streetAddress || '',
      city: leader.city || '',
      state: leader.state || '',
      zip: leader.zip || '',
      phone: leader.phone || '',
      product: leader.product || '',
      organization: leader.organization || '',
      sponsorFirstName: leader.sponsorFirstName || '',
      sponsorLastName: leader.sponsorLastName || '',
      sponsorEmail: leader.sponsorEmail || ''
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDeleteLeader = async customerId => {
    if (!window.confirm('Are you sure you want to delete this leader?')) return;
    try {
      await axios.delete(`${API_BASE_URL}/api/customers/${customerId}`, { withCredentials: true });
      alert('Leader deleted successfully.');
      fetchLeaders();
    } catch (error) {
      alert('Failed to delete leader.');
    }
  };

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const normalizeString = str => str.toLowerCase().replace(/\s+/g, ' ').trim();

  const filteredLeaders = useMemo(() => {
    return leaders.filter(leader => {
      const leaderString = `${leader.firstName} ${leader.lastName} ${JSON.stringify(leader)}`;
      return normalizeString(leaderString).includes(normalizeString(searchTerm));
    });
  }, [leaders, searchTerm]);

  const getCompletenessColor = leader => {
    const completeness = leader.completeness;
    const hasIncompleteSelfOrManager = leader.participants?.some(p => {
      return (p.relationship === 'Self' || p.relationship === 'Manager') && !p.completed;
    });
    if (completeness >= 60 && !hasIncompleteSelfOrManager) return 'hot';
    if (completeness >= 45 || hasIncompleteSelfOrManager) return 'medium';
    return 'low';
  };

  const isRegistered = leader => (leader.userId ? 'Registered' : 'Not Registered');

  const handleRaterReadinessLink = customerId => {
    navigate('/apex', { state: { searchQuery: customerId } });
  };

  const generatePDF = async (id, customerEmail) => {
    setIsGeneratingPdf(prev => ({ ...prev, [id]: true }));
    try {
      const response = await axios({
        method: 'post',
        url: `${API_BASE_URL}/api/generate-pdf`,
        data: { id },
        responseType: 'blob',
        withCredentials: true
      });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const downloadLink = document.createElement('a');
      downloadLink.href = pdfUrl;
      downloadLink.setAttribute('download', `${customerEmail}_PTEK_REPORT.pdf`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setTimeout(() => URL.revokeObjectURL(pdfUrl), 100);
      document.body.removeChild(downloadLink);
    } catch (error) {
      alert('Failed to generate PDF.');
    } finally {
      setIsGeneratingPdf(prev => ({ ...prev, [id]: false }));
    }
  };

  const handleCustomMessageChange = (id, value) => {
    setCustomMessages(prev => ({ ...prev, [id]: value }));
  };

  const handleSendEmail = async id => {
    const customMessage = customMessages[id] || '';
    setIsSendingEmail(prev => ({ ...prev, [id]: true }));
    setEmailStatusMessage('');
    try {
      await axios.post(`${API_BASE_URL}/api/email-registration`, { id, customMessage }, { withCredentials: true });
      setEmailStatusMessage('Registration email sent successfully.');
      fetchLeaders();
      setTimeout(() => setEmailStatusMessage(''), 5000);
    } catch (error) {
      setEmailStatusMessage('Failed to send registration email.');
      setTimeout(() => setEmailStatusMessage(''), 5000);
    } finally {
      setIsSendingEmail(prev => ({ ...prev, [id]: false }));
    }
  };

  const openEmailHistoryModal = emailHistory => {
    setSelectedEmailHistory(emailHistory || []);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row }) => (
        <div>{row.original.firstName} {row.original.lastName}</div>
      )
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Organization',
      accessor: 'organization'
    },
    {
      Header: 'Sponsor',
      accessor: 'sponsorEmail'
    },
    {
      Header: 'Product',
      accessor: 'product'
    },
    {
      Header: 'Readiness',
      accessor: 'completeness',
      Cell: ({ row }) => (
        <div className="completeness-indicator">
          <div className={`bar ${getCompletenessColor(row.original)}`} style={{ width: `${row.original.completeness}%` }}></div>
          <span>{row.original.completeness}%</span>
        </div>
      ),
      sortType: (a, b) => {
        const valA = a.values.completeness;
        const valB = b.values.completeness;
        return valA > valB ? -1 : valA < valB ? 1 : 0;
      }
    },
    {
      Header: 'Last Edited',
      accessor: 'createdAt',
      Cell: ({ value }) => (
        <div>{value ? new Date(value).toLocaleDateString() : 'N/A'}</div>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => <div>{isRegistered(row.original)}</div>
    }
  ], []);

  const data = useMemo(() => filteredLeaders, [filteredLeaders]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

  return (
    <div className="customer-maintenance-container">
      <h2>Leader Maintenance (Admin)</h2>
      <div className="customer-maintenance-form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input type="email" id="email" name="email" required value={formData.email} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="sponsorEmail">Sponsor Email</label>
            <input type="email" id="sponsorEmail" name="sponsorEmail" value={formData.sponsorEmail} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="organization">Organization</label>
            <input type="text" id="organization" name="organization" value={formData.organization} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="sponsorFirstName">Sponsor First Name</label>
            <input type="text" id="sponsorFirstName" name="sponsorFirstName" value={formData.sponsorFirstName} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="sponsorLastName">Sponsor Last Name</label>
            <input type="text" id="sponsorLastName" name="sponsorLastName" value={formData.sponsorLastName} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="product">Product</label>
            <select id="product" name="product" value={formData.product} onChange={handleInputChange}>
              {products.map(product => (
                <option key={product._id} value={product.productName}>
                  {product.productName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="streetAddress">Street Address</label>
            <input type="text" id="streetAddress" name="streetAddress" value={formData.streetAddress} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="city">City</label>
            <input type="text" id="city" name="city" value={formData.city} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="state">State</label>
            <input type="text" id="state" name="state" value={formData.state} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="zip">Zip</label>
            <input type="text" id="zip" name="zip" value={formData.zip} onChange={handleInputChange} />
          </div>
          <div className="form-group full-submit">
            <input type="submit" value={isEditMode ? 'Update Leader' : 'Add Leader'} />
          </div>
        </form>
      </div>
      <div className="customer-list">
        <input
          type="text"
          className="search-bar"
          placeholder="Search by name, product, or organization..."
          onChange={handleSearch}
          value={searchTerm}
        />
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  const { isSorted, isSortedDesc } = column;
                  const sortClass = isSorted ? (isSortedDesc ? 'sorted-desc' : 'sorted-asc') : '';
                  return (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={sortClass}>
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr {...row.getRowProps()} className="leader-row">
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                  <tr className="action-row">
                    <td colSpan={columns.length}>
                      <div className="action-buttons">
                        <button onClick={() => handleEditLeader(row.original)}>Edit</button>
                        <button onClick={() => handleDeleteLeader(row.original._id)}>Delete</button>
                        <button onClick={() => handleSendEmail(row.original._id)}>
                          {isSendingEmail[row.original._id] ? 'Sending...' : row.original.emailSent ? 'Resend Email' : 'Email Registration'}
                        </button>
                        <button onClick={() => handleRaterReadinessLink(row.original._id)}>Rater Readiness</button>
                        <button onClick={() => generatePDF(row.original._id, row.original.email)} disabled={isGeneratingPdf[row.original._id]}>
                          {isGeneratingPdf[row.original._id] ? 'Generating...' : 'Generate PDF'}
                        </button>
                        <button onClick={() => navigate(`/leader-visual-report/${row.original._id}`)}>View 360 Report</button>
                        <button className="right-aligned" onClick={() => openEmailHistoryModal(row.original.emailHistory)}>Email History</button>
                      </div>
                      <textarea
                        className="custom-message"
                        placeholder="Enter custom message"
                        rows={3}
                        value={customMessages[row.original._id] || ''}
                        onChange={e => handleCustomMessageChange(row.original._id, e.target.value)}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {emailStatusMessage && <div className="email-status-message">{emailStatusMessage}</div>}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Email History" className="Modal" overlayClassName="Overlay">
        <h2>Email History</h2>
        <button onClick={closeModal}>Close</button>
        <ul>
          {selectedEmailHistory.length > 0 ? (
            selectedEmailHistory.map((entry, index) => (
              <li key={index}>{new Date(entry.sentAt).toLocaleString()}</li>
            ))
          ) : (
            <li>No email history available</li>
          )}
        </ul>
      </Modal>
    </div>
  );
};

export default CustomerMaintenance;
