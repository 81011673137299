import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './css/Admin.css';
import API_BASE_URL from './config';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';

const Admin = () => {
  const [customers, setCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [products, setProducts] = useState([]);
  const [expandedCustomerId, setExpandedCustomerId] = useState(null);
  const [loadingParticipants, setLoadingParticipants] = useState(false);
  const [participants, setParticipants] = useState({});
  const [isGeneratingPdf, setIsGeneratingPdf] = useState({});
  const [emailStatusMessage, setEmailStatusMessage] = useState('');
  const [customMessages, setCustomMessages] = useState({});
  const [feedbackEmailStatusMessage, setFeedbackEmailStatusMessage] = useState('');
  const [isSendingEmail, setIsSendingEmail] = useState({});
  const [isSendingFeedback, setIsSendingFeedback] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [customersPerPage] = useState(10); // stays constant unless you want an input
  const [selectedCustomerIds, setSelectedCustomerIds] = useState(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [filterProductId, setFilterProductId] = useState('');
  const [isSendingBatchEmails, setIsSendingBatchEmails] = useState(false);
  const [batchCustomMessage, setBatchCustomMessage] = useState('');
  const [selectedParticipantIds, setSelectedParticipantIds] = useState(new Set());

  const location = useLocation();
  const customerRefs = useRef({});

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEmailHistory, setSelectedEmailHistory] = useState([]);

  // Modal handling
  const openEmailHistoryModal = (emailHistory) => {
    setSelectedEmailHistory(emailHistory || []);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Fetch data on mount
  useEffect(() => {
    const fetchData = async () => {
      await fetchCustomers();
      await fetchProducts();

      // If you navigated here with a specific searchQuery in location.state
      if (location.state && location.state.searchQuery) {
        const sq = location.state.searchQuery;
        setSearchQuery(sq);
      }
    };
    fetchData();
  }, [location]);

  // Expand the row if searchQuery is set
  useEffect(() => {
    if (searchQuery && customers.length > 0) {
      expandCustomerDetailsById(searchQuery);
    }
  }, [searchQuery, customers]);

  // Reset page if search changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  // ----- API calls -----
  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/customers`, { withCredentials: true });
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/products`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // ----- Filtering -----
  const filteredCustomers = customers.filter((cust) => {
    const fName = (cust.firstName || '').toLowerCase();
    const lName = (cust.lastName || '').toLowerCase();
    const email = (cust.email || '').toLowerCase();
    const cId = (cust._id || '').toLowerCase();
    const q = searchQuery.toLowerCase();

    const matchesSearch =
      fName.includes(q) || lName.includes(q) || email.includes(q) || cId.includes(q);

    const matchesProduct = filterProductId ? cust.product === filterProductId : true;
    return matchesSearch && matchesProduct;
  });

  // ----- Pagination -----
  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const Pagination = ({ totalCustomers, customersPerPage }) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalCustomers / customersPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav>
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  paginate(number);
                }}
                href="!#"
                className="page-link"
              >
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  // ----- Expand a single row -----
  const expandCustomerDetailsById = async (customerId) => {
    const cust = customers.find((c) => c._id === customerId);
    if (cust) {
      await toggleCustomerDetails(cust._id, cust.userId);
    }
  };

  const toggleCustomerDetails = async (custId, userId) => {
    if (expandedCustomerId === custId) {
      setExpandedCustomerId(null);
      return;
    }

    setLoadingParticipants(true);
    try {
      let response = { data: [] };
      if (custId) {
        response = await axios.get(`${API_BASE_URL}/api/leader-participants`, {
          withCredentials: true,
          params: { customerId: custId },
        });
      }
      setParticipants((prev) => ({ ...prev, [custId]: response.data }));
      setExpandedCustomerId(custId);

      // Scroll
      if (customerRefs.current[custId]) {
        customerRefs.current[custId].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } catch (error) {
      console.error('Error fetching participants:', error);
    } finally {
      setLoadingParticipants(false);
    }
  };

  // ----- Handling "Select All" leaders
  const handleSelectAllToggle = () => {
    if (selectAll) {
      setSelectedCustomerIds(new Set());
      setSelectAll(false);
    } else {
      const newSelection = new Set();
      filteredCustomers.forEach((c) => newSelection.add(c._id));
      setSelectedCustomerIds(newSelection);
      setSelectAll(true);
    }
  };

  const toggleCustomerSelection = (id) => {
    setSelectedCustomerIds((prev) => {
      const newSet = new Set(prev);
      newSet.has(id) ? newSet.delete(id) : newSet.add(id);
      return newSet;
    });
  };

  // ----- Single email logic -----
  const handleSendEmail = async (customerId) => {
    setIsSendingEmail((prev) => ({ ...prev, [customerId]: true }));
    setEmailStatusMessage('');
    try {
      await axios.post(
        `${API_BASE_URL}/api/email-registration`,
        { customerId },
        { withCredentials: true }
      );
      setEmailStatusMessage('Registration email sent successfully.');
      fetchCustomers();
      setTimeout(() => setEmailStatusMessage(''), 4000);
    } catch (error) {
      console.error('Error sending registration email:', error);
      setEmailStatusMessage('Failed to send registration email.');
      setTimeout(() => setEmailStatusMessage(''), 4000);
    } finally {
      setIsSendingEmail((prev) => ({ ...prev, [customerId]: false }));
    }
  };

  // ----- PDF Generation -----
  const generatePDF = async (customerId, email) => {
    setIsGeneratingPdf((prev) => ({ ...prev, [customerId]: true }));
    try {
      const resp = await axios.post(
        `${API_BASE_URL}/api/generate-pdf`,
        { customerId },
        {
          withCredentials: true,
          responseType: 'blob',
        }
      );
      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const dlLink = document.createElement('a');
      dlLink.href = pdfUrl;
      dlLink.setAttribute('download', `${email}_PTEK_REPORT.pdf`);
      document.body.appendChild(dlLink);
      dlLink.click();
      setTimeout(() => URL.revokeObjectURL(pdfUrl), 100);
      document.body.removeChild(dlLink);
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Failed to generate PDF.');
    } finally {
      setIsGeneratingPdf((prev) => ({ ...prev, [customerId]: false }));
    }
  };

  // ----- Deletion -----
  const handleCustomerDelete = async (customerId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/customers/${customerId}`, {
        withCredentials: true,
      });
      fetchCustomers();
    } catch (error) {
      console.error('Error deleting leader:', error);
    }
  };

  // ----- Participant logic -----
  const handleCustomMessageChange = (participantId, value) => {
    setCustomMessages((prev) => ({ ...prev, [participantId]: value }));
  };

  const toggleParticipantSelection = (participantId) => {
    setSelectedParticipantIds((prev) => {
      const newSet = new Set(prev);
      newSet.has(participantId) ? newSet.delete(participantId) : newSet.add(participantId);
      return newSet;
    });
  };

  const sendFeedbackForm = async (participantId, custId) => {
    setIsSendingFeedback((prev) => ({
      ...prev,
      [custId]: { ...(prev[custId] || {}), [participantId]: true },
    }));

    const customMessage = customMessages[participantId] || '';
    try {
      await axios.post(
        `${API_BASE_URL}/api/send-feedback-form`,
        { participantId, customMessage },
        { withCredentials: true }
      );
      setFeedbackEmailStatusMessage('Feedback form sent successfully.');
      setParticipants((prev) => ({
        ...prev,
        [custId]: prev[custId].map((p) =>
          p._id === participantId ? { ...p, sentEmail: true } : p
        ),
      }));
      setExpandedCustomerId(custId);
      setTimeout(() => setFeedbackEmailStatusMessage(''), 4000);
    } catch (error) {
      console.error('Error sending feedback form:', error);
      setFeedbackEmailStatusMessage('Failed to send feedback form.');
      setTimeout(() => setFeedbackEmailStatusMessage(''), 4000);
    } finally {
      setIsSendingFeedback((prev) => ({
        ...prev,
        [custId]: { ...(prev[custId] || {}), [participantId]: false },
      }));
    }
  };

  const sendBatchFeedbackForms = async (participantIds) => {
    setIsSendingBatchEmails(true);
    try {
      await axios.post(
        `${API_BASE_URL}/api/admin/batch-send-feedback-form`,
        { participantIds, customMessage: batchCustomMessage },
        { withCredentials: true }
      );
      setFeedbackEmailStatusMessage('Batch feedback forms sent successfully.');
      setTimeout(() => setFeedbackEmailStatusMessage(''), 4000);
    } catch (error) {
      console.error('Error sending batch feedback forms:', error);
      setFeedbackEmailStatusMessage('Failed to send batch feedback forms.');
      setTimeout(() => setFeedbackEmailStatusMessage(''), 4000);
    } finally {
      setIsSendingBatchEmails(false);
      setBatchCustomMessage('');
    }
  };

  // Render participants for a given leader
  const renderParticipants = (custId) => {
    const pData = participants[custId];
    if (!pData || pData.length === 0) {
      return <p className="no-raters">There are no raters assigned to this leader</p>;
    }

    return (
      <div>
        <p>
          Select multiple participants and click "Send Batch Feedback Forms" to email them all at
          once, or use "Send Feedback Form" for a single email.
        </p>
        <div>
          <input
            type="checkbox"
            onChange={() => {
              const allSelected = pData.every((p) => selectedParticipantIds.has(p._id));
              const newSet = new Set(selectedParticipantIds);
              pData.forEach((p) => {
                allSelected ? newSet.delete(p._id) : newSet.add(p._id);
              });
              setSelectedParticipantIds(newSet);
            }}
            checked={pData.every((p) => selectedParticipantIds.has(p._id))}
          />
          <label>Select All Participants</label>
        </div>
        <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
          {pData.map((participant) => (
            <li key={participant._id} style={{ marginBottom: '10px' }}>
              <input
                type="checkbox"
                checked={selectedParticipantIds.has(participant._id)}
                onChange={() => toggleParticipantSelection(participant._id)}
              />
              {' '}
              {participant.email} - {participant.relationship} -{' '}
              {participant.completed ? (
                <span className="completed">✔ Completed</span>
              ) : (
                <span className="incomplete">✘ Not Completed</span>
              )}
              {' '}
              {participant.sentEmail ? (
                <span className="completed">✔ Email Sent</span>
              ) : (
                <span className="incomplete">✘ Not Sent</span>
              )}
              <br />
              <textarea
                className="custom-message"
                placeholder="Custom Message"
                value={customMessages[participant._id] || ''}
                onChange={(e) => handleCustomMessageChange(participant._id, e.target.value)}
                rows={3}
              />
              <button
                onClick={() => sendFeedbackForm(participant._id, custId)}
                disabled={
                  isSendingFeedback[custId]?.[participant._id] ||
                  selectedParticipantIds.size > 1
                }
              >
                {isSendingFeedback[custId]?.[participant._id]
                  ? 'Sending...'
                  : participant.sentEmail
                  ? 'Resend'
                  : 'Send Feedback Form'}
              </button>
            </li>
          ))}
        </ul>
        <div className="batch-custom-message">
          <textarea
            className="custom-message"
            placeholder="Batch Custom Message"
            value={batchCustomMessage}
            onChange={(e) => setBatchCustomMessage(e.target.value)}
            rows={3}
          />
        </div>
        <button
          onClick={() => sendBatchFeedbackForms(Array.from(selectedParticipantIds))}
          disabled={!selectedParticipantIds.size || isSendingBatchEmails}
          className={selectedParticipantIds.size ? 'highlight' : ''}
        >
          {isSendingBatchEmails ? 'Sending...' : 'Send Batch Feedback Forms'}
        </button>
      </div>
    );
  };

  // Render entire leader list
  const renderCustomerList = () => {
    return currentCustomers.map((cust) => {
      const participantData = participants[cust._id] || [];
      const totalParticipants = participantData.length;
      const completed = participantData.filter((p) => p.completed).length;
      const completionRate =
        totalParticipants > 0 ? ((completed / totalParticipants) * 100).toFixed(2) : 'N/A';

      return (
        <div
          key={cust._id}
          ref={(el) => (customerRefs.current[cust._id] = el)}
          className={`customer-item ${
            cust.readyToNotifyParticipants ? 'customer-item-ready' : ''
          }`}
        >
          <input
            type="checkbox"
            checked={selectedCustomerIds.has(cust._id)}
            onChange={() => toggleCustomerSelection(cust._id)}
          />
          <div>
            <strong>ID:</strong> {cust._id}
          </div>
          <div>
            <strong>Name:</strong> {cust.firstName} {cust.lastName}
          </div>
          <div>
            <strong>Email:</strong> {cust.email}
          </div>
          <div>
            <strong>Product:</strong> {cust.product}
          </div>
          {participantData.length > 0 && (
            <div className="completion-percentage">
              <strong>Completion Rate:</strong> {completionRate}%
            </div>
          )}
          {cust.readyToNotifyParticipants && (
            <div className="notification-status">
              This Leader is ready to notify raters to begin the survey!
            </div>
          )}
          <div>
            <strong>Created At:</strong>{' '}
            {cust.createdAt ? new Date(cust.createdAt).toLocaleString() : 'N/A'}
          </div>
          <div>
            {cust.emailSent ? (
              <span className="email-sent-indicator">Registration Email Sent... Resend?</span>
            ) : (
              <span className="email-not-sent-indicator">Registration Email Not Sent</span>
            )}
          </div>
          <button onClick={() => handleSendEmail(cust._id)}>
            {isSendingEmail[cust._id]
              ? 'Sending...'
              : cust.emailSent
              ? 'Resend Email'
              : 'Email Registration'}
          </button>
          <button onClick={() => generatePDF(cust._id, cust.email)} disabled={isGeneratingPdf[cust._id]}>
            {isGeneratingPdf[cust._id] ? 'Generating...' : 'Generate PDF'}
          </button>
          <button onClick={() => toggleCustomerDetails(cust._id, cust.userId)}>
            {expandedCustomerId === cust._id ? 'Hide Details' : 'Show Details'}
          </button>
          <button onClick={() => handleCustomerDelete(cust._id)}>Delete</button>

          {cust.emailHistory && cust.emailHistory.length > 0 ? (
            <div className="right-aligned email-history">
              <span>
                <b>Email History: </b>
              </span>
              <button onClick={() => openEmailHistoryModal(cust.emailHistory)}>View History</button>
            </div>
          ) : (
            <div className="right-aligned email-history">
              <span>
                <b>Email History: </b>
              </span>
              <button disabled>No email history</button>
            </div>
          )}

          {expandedCustomerId === cust._id && (
            <div className="customer-details">
              {loadingParticipants ? <p>Loading...</p> : renderParticipants(cust._id)}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="admin-container">
      <h2>Rater Readiness (Admin)</h2>

      <div className="controls-section">
        <div className="search-bar-container">
          <input
            type="text"
            placeholder="Search Leaders..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="select-all-container">
          <input type="checkbox" checked={selectAll} onChange={handleSelectAllToggle} />
          <label>Select All</label>
        </div>
        <div className="filter-by-productId">
          <select value={filterProductId} onChange={(e) => setFilterProductId(e.target.value)}>
            <option value="">All Products</option>
            {products.map((product) => (
              <option key={product._id} value={product.productName}>
                {product.productName}
              </option>
            ))}
          </select>
        </div>
      </div>

      {emailStatusMessage && <div className="email-status-message">{emailStatusMessage}</div>}
      {feedbackEmailStatusMessage && (
        <div className="feedback-email-status-message">{feedbackEmailStatusMessage}</div>
      )}

      <div className="customer-list">
        <h3>Leader List</h3>

        <Pagination totalCustomers={filteredCustomers.length} customersPerPage={customersPerPage} />

        {renderCustomerList()}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Email History"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>Email History</h2>
        <button onClick={closeModal}>Close</button>
        <ul>
          {selectedEmailHistory.length > 0 ? (
            selectedEmailHistory.map((entry, index) => (
              <li key={index}>{new Date(entry.sentAt).toLocaleString()}</li>
            ))
          ) : (
            <li>No email history available</li>
          )}
        </ul>
      </Modal>
    </div>
  );
};

export default Admin;
