import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext'; // Make sure this path is correct
import './css/MainIndex.css';

const MainIndex = () => {
  const { isAdmin, user } = useContext(AuthContext);

  // Optional: Greet by user’s first name or username if you have that
  const userName = user?.username || 'Leader';

  // Build an array of “cards” to display, conditionally
  const userCards = [
    {
      show: !isAdmin,
      route: '/dashboard',
      title: 'Add / Update Raters',
      description: 'Manage your rater list, invite them to give feedback.',
    },
    {
      show: !isAdmin,
      route: '/leader-analytics',
      title: 'Leader Analytics',
      description: 'View your feedback analytics and gain insights.',
    },
  ];

  const adminCards = [
    {
      show: isAdmin,
      route: '/customer-maintenance',
      title: 'Leader Maintenance',
      description: 'Create, edit, and manage leaders in the system.',
    },
    {
      show: isAdmin,
      route: '/rater-maintenance',
      title: 'Rater Maintenance',
      description: 'Manually manage the rater lists for each leader.',
    },
    {
      show: isAdmin,
      route: '/apex',
      title: 'Rater Readiness',
      description: 'Track completion rates and send reminders to raters.',
    },
    {
      show: isAdmin,
      route: '/product',
      title: 'Product Maintenance',
      description: 'Add or update PeopleTek coaching products.',
    },
    {
      show: isAdmin,
      route: '/ai-dashboard',
      title: 'AI Dashboard',
      description: 'Let AI summarize or analyze the collected feedback.',
    },
    {
      show: isAdmin,
      route: '/bulk-upload',
      title: 'Bulk Upload',
      description: 'Upload leaders or raters in batches via CSV.',
    },
  ];

  const cardsToDisplay = [...userCards, ...adminCards].filter((c) => c.show);

  return (
    <div className="main-index-container">
      <header className="main-index-header">
        <h1>Hello, {userName}!</h1>
        <p className="main-index-subtitle">
          Welcome to your {isAdmin ? 'Admin' : 'Leader'} Dashboard. What would you like to do next?
        </p>
      </header>

      <div className="index-cards-grid">
        {cardsToDisplay.map((card) => (
          <div key={card.route} className="index-card">
            <h2 className="card-title">{card.title}</h2>
            {card.description && <p className="card-description">{card.description}</p>}
            <Link to={card.route} className="card-button">
              Go
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainIndex;
