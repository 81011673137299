import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './css/NavigationBar.module.css';
import { AuthContext } from './AuthContext';

const NavigationBar = () => {
    const { isAuthenticated, signOut, user } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSignOut = async () => {
        await signOut();
        navigate('/');
    };

    return (
        <nav className={styles.navbar}>
            <div className={styles.navLeft}>
                <Link to={isAuthenticated ? "/main-index" : "/"} className={styles.brand}>
                    PeopleTek Coaching
                </Link>
            </div>
            <div className={styles.navRight}>
                {isAuthenticated ? (
                    <>
                        <Link to="/" className={styles.navLink}>Home</Link>
                        <Link to="/main-index" className={styles.navLink}>My Dashboard</Link>
                        {/* Display user name/email or role */}
                        {user && (
                            <span className={styles.userInfo}>
                                {user?.name || user?.username} ({user?.email})
                            </span>
                        )}
                        <button onClick={handleSignOut} className={styles.navButton}>Sign Out</button>
                    </>
                ) : (
                    <>
                        <Link to="/signup" className={styles.navLink}>Sign Up</Link>
                        <Link to="/login" className={styles.navLink}>Login</Link>
                    </>
                )}
            </div>
        </nav>
    );
};

export default NavigationBar;
