import React from 'react';
import './css/LandingPage.css';

const LandingPage = () => {
  const scrollToContent = () => {
    const contentSection = document.getElementById("info-section");
    if (contentSection) {
      contentSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="landing-container">
      {/* Hero / Top Section */}
      <section className="landing-hero">
        <div className="hero-overlay">
          <div className="landing-content">
            <h1>Welcome to PeopleTek 360 Compass</h1>
            <p>Your journey to leadership excellence starts here.</p>
            <a href="mailto:360Feedback@peopletekcoaching.com" className="contact-button">
              Contact Us
            </a>
            <div className="scroll-indicator" onClick={scrollToContent}>
              <span className="scroll-text">Scroll Down</span>
              <span className="arrow-down">▼</span>
            </div>
          </div>
        </div>
      </section>

      {/* Info / Secondary Section */}
      <section id="info-section" className="landing-info-section">
        <h2>About Our 360 Feedback Process</h2>
        <p>
          PeopleTek 360 Compass is a comprehensive feedback platform designed to 
          elevate leaders through actionable insights, real-time analytics, 
          and a supportive coaching ecosystem.
        </p>
        <p>
          Our mission is to empower organizations and individuals to foster 
          meaningful growth, build cohesive teams, and drive excellence in leadership. 
          We combine proven coaching methodologies with cutting-edge data 
          analytics to deliver a seamless and transformative experience.
        </p>

        {/* Tzen Analytics Branding */}
        <div className="tzen-branding">
          <h3>Powered by Tzen Analytics</h3>
          <div className="genome-container">
            {/* The spiraling genome icon + animation */}
            <div className="spiral-genome"></div>
            <p>
              Our advanced analytics platform processes feedback data 
              instantly to produce actionable insights tailored for your leadership journey.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
