import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LandingPage from './LandingPage';
import SignUp from './SignUp';
import Login from './Login';
import FeedbackForm from './FeedbackForm';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './AuthContext';
import LeaderDashboard from './LeaderDashboard';
import AdminDashboard from './AdminDashboard';
import NavigationBar from './NavigationBar';
import ProductPage from './ProductPage';
import MainIndex from './MainIndex';
import CustomerMaintenance from './CustomerMaintenance';
import ParticipantMaintenance from './ParticipantMaintenance';
import LeaderAnalytics from './LeaderAnalytics';
import AdminAIDashboard from './AdminAIDashboard';
import ProductRegistrationStatus from './ProductRegistrationStatus';
import ProductResponseStatus from './ProductResponseStatus'; // Import the new component
import BulkUpload from './BulkUploadComponent'; // Import the new BulkUpload component
import LeaderFeedbackVisualization from './LeaderFeedbackVisualization'; // we'll create this soon

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <RouteConditionallyRenderNavbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/feedback/:uniqueToken" element={<FeedbackForm />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <LeaderDashboard />
            </PrivateRoute>
          } />
          <Route path="/apex" element={
            <PrivateRoute roleRequired={"admin"}>
              <AdminDashboard />
            </PrivateRoute>
          } />
          <Route path="/product" element={
            <PrivateRoute>
              <ProductPage />
            </PrivateRoute>
          } />
          <Route path="/main-index" element={
            <PrivateRoute>
              <MainIndex />
            </PrivateRoute>} /> 
          <Route path="/customer-maintenance" element={
            <PrivateRoute>
              <CustomerMaintenance />
            </PrivateRoute>} /> 
          <Route path="/rater-maintenance" element={
            <PrivateRoute>
              <ParticipantMaintenance />
            </PrivateRoute>} />
          <Route path="/bulk-upload" element={ // New route for Bulk Upload
            <PrivateRoute roleRequired={"admin"}>
              <BulkUpload />
            </PrivateRoute>} />
          <Route path="/ai-dashboard" element={
            <PrivateRoute>
              <AdminAIDashboard />
            </PrivateRoute>} />
          <Route path="/product-registration-status" element={ <PrivateRoute><ProductRegistrationStatus /> </PrivateRoute>} />
          <Route
            path="/leader-visual-report/:customerId"
            element={
              <PrivateRoute roleRequired={"admin"}>
                <LeaderFeedbackVisualization />
              </PrivateRoute>
            }
          />
          <Route path="/product-response-status" element={ // New route for Product Response Status
            <PrivateRoute>
              <ProductResponseStatus />
            </PrivateRoute>} />
          <Route path="/leader-analytics" element={
            <PrivateRoute>
              <LeaderAnalytics />
            </PrivateRoute>} /> 
        </Routes>
      </AuthProvider>
    </Router>
  );
};

const RouteConditionallyRenderNavbar = () => {
  const location = useLocation();

  // Render the NavigationBar only if the current path does not start with /feedback/
  if (!location.pathname.startsWith('/feedback/')) {
    return <NavigationBar />;
  }

  return null;
};

export default App;
