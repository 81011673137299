import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Recharts components
import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Tooltip, Legend,
  BarChart, Bar, XAxis, YAxis, CartesianGrid,
} from 'recharts';

import API_BASE_URL from './config';

/**
 * Each category maps to 3 question indices.
 */
const COMPASS_CATEGORIES = [
  { name: 'Continual Learning', questionIndices: [0, 1, 2] },
  { name: 'Awareness',          questionIndices: [3, 4, 5] },
  { name: 'Magic Dust',         questionIndices: [6, 7, 8] },
  { name: 'VMGM=B',             questionIndices: [9, 10, 11] },
  { name: 'Communications',     questionIndices: [12, 13, 14] },
  { name: 'Clarity',            questionIndices: [15, 16, 17] },
  { name: 'Accountability',     questionIndices: [18, 19, 20] },
  { name: 'Conflict',           questionIndices: [21, 22, 23] },
  { name: 'Influence',          questionIndices: [24, 25, 26] },
  { name: 'Relationships',      questionIndices: [27, 28, 29] },
  { name: 'Feedback',           questionIndices: [30, 31, 32] },
  { name: 'Inspiration',        questionIndices: [33, 34, 35] },
];

/**
 * Score legend (0..5 typical). 
 * We keep domain [0..6] so there's some spacing above 5,
 * or if you have "Don't Know" coded as 0 and "Always" as 5.
 */
const SCORE_LEGEND = [
  { score: 0, label: 'Don’t Know' },
  { score: 1, label: 'Never' },
  { score: 2, label: 'Rarely' },
  { score: 3, label: 'Sometimes' },
  { score: 4, label: 'Often' },
  { score: 5, label: 'Always' },
];

// Some shared style objects
const containerStyle = {
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '1rem',
  fontFamily: 'sans-serif',
  backgroundColor: '#f9f9f9',
};

const headerStyle = {
  textAlign: 'center',
  marginBottom: '2rem',
};

const sectionStyle = {
  marginBottom: '2rem',
  padding: '1rem',
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
};

const sectionHeadingStyle = {
  marginBottom: '1rem',
  borderBottom: '2px solid #ccc',
  paddingBottom: '0.5rem',
};

const legendListStyle = {
  listStyle: 'none',
  paddingLeft: 0,
  marginTop: '0.5rem',
};

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
  backgroundColor: '#fff',
};

const thTdStyle = {
  border: '1px solid #ccc',
  padding: '8px',
};

const categoryHeaderRowStyle = {
  backgroundColor: '#f0f0f0',
};

const subCategoryRowStyle = {
  backgroundColor: '#fff',
};

/**
 * If your data is actually stored with a certain offset 
 * (e.g., 1..6 instead of 0..5), you can adjust here.
 * Example: subtract 1 from every question score if needed:
 */
function maybeAdjustScore(rawScore) {
  // Uncomment if you need to shift from [1..6] to [0..5]:
  // return Math.max(0, rawScore - 1);
  return rawScore; 
}

const LeaderFeedbackVisualization = () => {
  const { customerId } = useParams();

  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [formResponses, setFormResponses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Example: /api/customer-visual-report/:customerId
        const resp = await axios.get(`${API_BASE_URL}/api/customer-visual-report/${customerId}`, {
          withCredentials: true,
        });
        setCustomer(resp.data.customer);
        setFormResponses(resp.data.formResponses);
      } catch (err) {
        console.error('Error loading report data:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [customerId]);

  if (loading) {
    return <div style={containerStyle}>Loading chart data...</div>;
  }
  if (!customer) {
    return <div style={containerStyle}>No customer found.</div>;
  }

  // Helper to average an array of numbers
  const average = (arr) => (arr.length ? arr.reduce((a, b) => a + b, 0) / arr.length : 0);

  // Convert raw questions to adjusted scores
  const adjustResponseScores = (resp) => {
    return resp.questions.map(q => maybeAdjustScore(q));
  };

  // We'll build "Self" vs "Other" sets
  // Overwrite each response's questions with adjusted scores:
  formResponses.forEach(resp => {
    resp.questions = adjustResponseScores(resp);
  });

  const selfResponse = formResponses.find(r => r.relationshipToSubject === 'Self');
  const otherResponses = formResponses.filter(r => r.relationshipToSubject !== 'Self');

  // Compute category averages for Self
  const selfCategoryAverages = Array.from({ length: COMPASS_CATEGORIES.length }, () => 0);
  if (selfResponse) {
    COMPASS_CATEGORIES.forEach((cat, idx) => {
      const catScores = cat.questionIndices.map(qIndex => selfResponse.questions[qIndex]);
      selfCategoryAverages[idx] = average(catScores);
    });
  }

  // Prepare arrays for All Others + subgroups
  const allOthersCategoryScores = COMPASS_CATEGORIES.map(() => []);
  const managerCategoryScores = COMPASS_CATEGORIES.map(() => []);
  const peerCategoryScores = COMPASS_CATEGORIES.map(() => []);
  const directReportCategoryScores = COMPASS_CATEGORIES.map(() => []);
  const otherCategoryScores = COMPASS_CATEGORIES.map(() => []);

  otherResponses.forEach((resp) => {
    COMPASS_CATEGORIES.forEach((cat, catIdx) => {
      const catScores = cat.questionIndices.map(qIndex => resp.questions[qIndex]);
      const catAvg = average(catScores);
      allOthersCategoryScores[catIdx].push(catAvg);
    });

    switch (resp.relationshipToSubject) {
      case 'Manager':
        COMPASS_CATEGORIES.forEach((cat, catIdx) => {
          const avgVal = average(cat.questionIndices.map(q => resp.questions[q]));
          managerCategoryScores[catIdx].push(avgVal);
        });
        break;
      case 'Peer':
        COMPASS_CATEGORIES.forEach((cat, catIdx) => {
          const avgVal = average(cat.questionIndices.map(q => resp.questions[q]));
          peerCategoryScores[catIdx].push(avgVal);
        });
        break;
      case 'Direct Report':
        COMPASS_CATEGORIES.forEach((cat, catIdx) => {
          const avgVal = average(cat.questionIndices.map(q => resp.questions[q]));
          directReportCategoryScores[catIdx].push(avgVal);
        });
        break;
      default:
        // relationship=Other
        COMPASS_CATEGORIES.forEach((cat, catIdx) => {
          const avgVal = average(cat.questionIndices.map(q => resp.questions[q]));
          otherCategoryScores[catIdx].push(avgVal);
        });
        break;
    }
  });

  const allOthersCategoryAverages = allOthersCategoryScores.map(average);
  const managerCatAverages = managerCategoryScores.map(average);
  const peerCatAverages = peerCategoryScores.map(average);
  const directReportCatAverages = directReportCategoryScores.map(average);
  const otherCatAverages = otherCategoryScores.map(average);

  // 1) Radar data: Self vs All
  const radarDataSelfVsAll = COMPASS_CATEGORIES.map((cat, i) => ({
    category: cat.name,
    self: selfCategoryAverages[i],
    allRaters: allOthersCategoryAverages[i],
  }));

  // 2) Radar data: All only
  const radarDataAllOnly = COMPASS_CATEGORIES.map((cat, i) => ({
    category: cat.name,
    allRaters: allOthersCategoryAverages[i],
  }));

  // 3) Subgroup radars
  const radarDataManager = COMPASS_CATEGORIES.map((cat, i) => ({
    category: cat.name,
    manager: managerCatAverages[i],
  }));
  const radarDataPeers = COMPASS_CATEGORIES.map((cat, i) => ({
    category: cat.name,
    peers: peerCatAverages[i],
  }));
  const radarDataDirectReports = COMPASS_CATEGORIES.map((cat, i) => ({
    category: cat.name,
    directReports: directReportCatAverages[i],
  }));
  const radarDataOther = COMPASS_CATEGORIES.map((cat, i) => ({
    category: cat.name,
    other: otherCatAverages[i],
  }));

  // 4) Vertical bar data: All Raters
  const barDataAll = COMPASS_CATEGORIES.map((cat, i) => ({
    category: cat.name,
    avgScore: allOthersCategoryAverages[i],
  }));

  // For table sub-questions
  const getSingleQuestionAverages = (questionIndex) => {
    const selfVal = selfResponse ? selfResponse.questions[questionIndex] : 0;

    const others = otherResponses.map(r => r.questions[questionIndex]);
    const avgAllOthers = average(others);

    const directOnly = otherResponses
      .filter(r => r.relationshipToSubject === 'Direct Report')
      .map(r => r.questions[questionIndex]);
    const avgDirect = average(directOnly);

    return {
      self: selfVal,
      all: avgAllOthers,
      direct: avgDirect,
    };
  };

  // Build table rows
  const tableRows = COMPASS_CATEGORIES.map(cat => {
    const subRows = cat.questionIndices.map((qIndex) => {
      const single = getSingleQuestionAverages(qIndex);
      return {
        questionLabel: `Q${qIndex + 1} placeholder`,
        self: single.self.toFixed(2),
        all: single.all.toFixed(2),
        direct: single.direct.toFixed(2),
      };
    });
    return {
      category: cat.name,
      subRows,
    };
  });

  // Horizontal bar charts
  const buildHorizontalDataForCategory = (cat) => {
    return cat.questionIndices.map(qIndex => {
      const others = otherResponses.map(r => r.questions[qIndex]);
      return {
        name: `Q${qIndex + 1}`,
        avg: average(others),
      };
    });
  };
  const horizontalBarData = COMPASS_CATEGORIES.map(cat => ({
    categoryName: cat.name,
    data: buildHorizontalDataForCategory(cat),
  }));

  // Written feedback
  const allWrittenFeedback = formResponses.map((resp, i) => ({
    relationship: resp.relationshipToSubject,
    strengths: resp.strengths,
    doDifferently: resp.doDifferently,
    additionalComments: resp.additionalComments,
  }));

  return (
    <div style={containerStyle}>
      {/* HEADER */}
      <div style={headerStyle}>
        <h1>Peopletek 360 Feedback Profile</h1>
        <h2>{customer.firstName} {customer.lastName}</h2>
        <p>Email: {customer.email}</p>
      </div>

      {/* Radar: Self vs All (bigger + centered) */}
      <section style={sectionStyle}>
        <h3 style={sectionHeadingStyle}>Radar: Self vs. All Raters</h3>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RadarChart
            cx={350}
            cy={270}
            outerRadius={200}
            width={700}
            height={550}
            data={radarDataSelfVsAll}
          >
            <PolarGrid />
            <PolarAngleAxis dataKey="category" />
            <PolarRadiusAxis angle={30} domain={[0, 6]} />
            <Tooltip />
            <Legend />
            <Radar
              name="Self"
              dataKey="self"
              stroke="#8884d8"
              fill="#8884d8"
              fillOpacity={0.6}
            />
            <Radar
              name="All Raters"
              dataKey="allRaters"
              stroke="#82ca9d"
              fill="#82ca9d"
              fillOpacity={0.6}
            />
          </RadarChart>
        </div>
      </section>

      {/* Radar: All Only (bigger + centered) */}
      <section style={sectionStyle}>
        <h3 style={sectionHeadingStyle}>Radar: All Raters Only (Averaged)</h3>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RadarChart
            cx={350}
            cy={270}
            outerRadius={200}
            width={700}
            height={550}
            data={radarDataAllOnly}
          >
            <PolarGrid />
            <PolarAngleAxis dataKey="category" />
            <PolarRadiusAxis angle={30} domain={[0, 6]} />
            <Tooltip />
            <Legend />
            <Radar
              name="All Raters"
              dataKey="allRaters"
              stroke="#82ca9d"
              fill="#82ca9d"
              fillOpacity={0.6}
            />
          </RadarChart>
        </div>
      </section>

      {/* Four smaller radars */}
      <section style={sectionStyle}>
        <h3 style={sectionHeadingStyle}>Radar by Relationship (Manager, Peers, Directs, Other)</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '2rem', justifyContent: 'center' }}>
          <div>
            <h4>Manager</h4>
            <RadarChart
              cx={150}
              cy={150}
              outerRadius={80}
              width={300}
              height={300}
              data={radarDataManager}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="category" />
              <PolarRadiusAxis angle={30} domain={[0, 6]} />
              <Tooltip />
              <Legend />
              <Radar
                name="Manager"
                dataKey="manager"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
              />
            </RadarChart>
          </div>
          <div>
            <h4>Peers</h4>
            <RadarChart
              cx={150}
              cy={150}
              outerRadius={80}
              width={300}
              height={300}
              data={radarDataPeers}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="category" />
              <PolarRadiusAxis angle={30} domain={[0, 6]} />
              <Tooltip />
              <Legend />
              <Radar
                name="Peers"
                dataKey="peers"
                stroke="#82ca9d"
                fill="#82ca9d"
                fillOpacity={0.6}
              />
            </RadarChart>
          </div>
          <div>
            <h4>Direct Reports</h4>
            <RadarChart
              cx={150}
              cy={150}
              outerRadius={80}
              width={300}
              height={300}
              data={radarDataDirectReports}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="category" />
              <PolarRadiusAxis angle={30} domain={[0, 6]} />
              <Tooltip />
              <Legend />
              <Radar
                name="Direct Reports"
                dataKey="directReports"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
              />
            </RadarChart>
          </div>
          <div>
            <h4>Other</h4>
            <RadarChart
              cx={150}
              cy={150}
              outerRadius={80}
              width={300}
              height={300}
              data={radarDataOther}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="category" />
              <PolarRadiusAxis angle={30} domain={[0, 6]} />
              <Tooltip />
              <Legend />
              <Radar
                name="Other"
                dataKey="other"
                stroke="#82ca9d"
                fill="#82ca9d"
                fillOpacity={0.6}
              />
            </RadarChart>
          </div>
        </div>
      </section>

      {/* Vertical bar chart */}
      <section style={sectionStyle}>
        <h3 style={sectionHeadingStyle}>Vertical Bar Chart: All Raters (Average by Category)</h3>
        <BarChart
          width={800}
          height={400}
          data={barDataAll}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="category" />
          <YAxis domain={[0, 6]} />
          <Tooltip />
          <Legend />
          <Bar dataKey="avgScore" name="Avg Score" fill="#8884d8" />
        </BarChart>

        {/* Score legend */}
        <div style={{ marginTop: '1rem' }}>
          <strong>Score Legend:</strong>
          <ul style={legendListStyle}>
            {SCORE_LEGEND.map(item => (
              <li key={item.score}>
                <b>{item.score}:</b> {item.label}
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Table for sub-questions */}
      <section style={sectionStyle}>
        <h3 style={sectionHeadingStyle}>Table of Compass Categories & Sub-Questions</h3>
        <p>(Averages for Self, All Raters, and Direct Reports, per sub-question.)</p>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thTdStyle}>Category</th>
              <th style={thTdStyle}>Sub-Question</th>
              <th style={thTdStyle}>Self</th>
              <th style={thTdStyle}>All Raters</th>
              <th style={thTdStyle}>Direct Reports</th>
            </tr>
          </thead>
          <tbody>
            {tableRows.map((row, idx) => (
              <React.Fragment key={idx}>
                <tr style={categoryHeaderRowStyle}>
                  <td style={{ ...thTdStyle, fontWeight: 'bold' }} colSpan={5}>
                    {row.category}
                  </td>
                </tr>
                {row.subRows.map((sub, sIdx) => (
                  <tr key={sIdx} style={subCategoryRowStyle}>
                    <td style={thTdStyle}></td>
                    <td style={thTdStyle}>{sub.questionLabel}</td>
                    <td style={thTdStyle}>{sub.self}</td>
                    <td style={thTdStyle}>{sub.all}</td>
                    <td style={thTdStyle}>{sub.direct}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        {/* Legend again if needed */}
        <div style={{ marginTop: '1rem' }}>
          <strong>Score Legend:</strong>
          <ul style={legendListStyle}>
            {SCORE_LEGEND.map(item => (
              <li key={item.score}>
                <b>{item.score}:</b> {item.label}
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Horizontal bar charts */}
      <section style={sectionStyle}>
        <h3 style={sectionHeadingStyle}>12 Horizontal Bar Charts (Averages for each sub-question)</h3>
        {horizontalBarData.map((catData, idx) => (
          <div
            key={idx}
            style={{
              marginBottom: '2rem',
              backgroundColor: '#fafafa',
              padding: '1rem',
              borderRadius: '6px',
            }}
          >
            <h4 style={{ marginBottom: '1rem' }}>{catData.categoryName}</h4>
            <BarChart
              width={500}
              height={200}
              data={catData.data}
              layout="vertical"
              margin={{ top: 20, right: 20, bottom: 20, left: 80 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" domain={[0,6]} />
              <YAxis dataKey="name" type="category" />
              <Tooltip />
              <Legend />
              <Bar dataKey="avg" fill="#82ca9d" name="Avg Score" />
            </BarChart>
          </div>
        ))}
      </section>

      {/* Written Feedback */}
      <section style={sectionStyle}>
        <h3 style={sectionHeadingStyle}>Written Feedback</h3>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thTdStyle}>Relationship</th>
              <th style={thTdStyle}>Strengths</th>
              <th style={thTdStyle}>Do Differently</th>
              <th style={thTdStyle}>Additional Comments</th>
            </tr>
          </thead>
          <tbody>
            {formResponses.map((resp, i) => (
              <tr key={i} style={subCategoryRowStyle}>
                <td style={thTdStyle}>{resp.relationshipToSubject}</td>
                <td style={thTdStyle}>{resp.strengths || ''}</td>
                <td style={thTdStyle}>{resp.doDifferently || ''}</td>
                <td style={thTdStyle}>{resp.additionalComments || ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default LeaderFeedbackVisualization;
