import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './css/Product.css';
import API_BASE_URL from './config';

const ProductPage = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({
    productName: '',
    launchDate: '',
    coachName: '',
    raterInviteDate: '',
    surveyEndDate: '',
    participants: 0
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [editProductId, setEditProductId] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/products`);
      const validProducts = response.data.filter(
        (p) => p.productName && p.productName.trim() !== ''
      );
      setProducts(validProducts);
    } catch (error) {
      alert('Failed to fetch products.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prev) => ({ ...prev, [name]: value }));
  };

  const resetForm = () => {
    setProduct({
      productName: '',
      launchDate: '',
      coachName: '',
      raterInviteDate: '',
      surveyEndDate: '',
      participants: 0
    });
    setIsEditMode(false);
    setEditProductId(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!product.productName.trim()) {
      alert('Product name is required.');
      return;
    }
    try {
      if (isEditMode) {
        await axios.put(`${API_BASE_URL}/api/products/${editProductId}`, product, {
          withCredentials: true
        });
        alert('Product updated successfully!');
      } else {
        await axios.post(`${API_BASE_URL}/api/products`, product, { withCredentials: true });
        alert('Product created successfully!');
      }
      fetchProducts();
      resetForm();
    } catch {
      alert('Failed to submit product.');
    }
  };

  const handleEdit = (p) => {
    setIsEditMode(true);
    setEditProductId(p._id);
    setProduct({
      productName: p.productName || '',
      launchDate: p.launchDate ? p.launchDate.slice(0, 10) : '',
      coachName: p.coachName || '',
      raterInviteDate: p.raterInviteDate ? p.raterInviteDate.slice(0, 10) : '',
      surveyEndDate: p.surveyEndDate ? p.surveyEndDate.slice(0, 10) : '',
      participants: p.participantCount || 0
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDeleteProduct = async (productName) => {
    if (!window.confirm('Are you sure you want to delete this product?')) return;
    try {
      await axios.delete(`${API_BASE_URL}/api/products/${productName}`, { withCredentials: true });
      alert('Product deleted successfully!');
      fetchProducts();
    } catch (error) {
      alert(error.response?.data?.message || 'Failed to delete product.');
    }
  };

  const handleRegStatus = (p) => {
    navigate('/product-registration-status', { state: { productName: p.productName } });
  };

  const handleResponseStatus = (p) => {
    navigate('/product-response-status', { state: { productName: p.productName } });
  };

  return (
    <div className="products-container">
      <div className="product-maintenance-container">
        <h1>Product Maintenance</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="coachName">Coach Name</label>
            <input
              type="text"
              id="coachName"
              name="coachName"
              value={product.coachName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="productName">Product Name *</label>
            <input
              type="text"
              id="productName"
              name="productName"
              required
              value={product.productName}
              onChange={handleChange}
            />
          </div>
          {isEditMode && (
            <div className="form-group">
              <label htmlFor="participants">Participants</label>
              <input
                type="number"
                id="participants"
                name="participants"
                value={product.participants}
                readOnly
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="launchDate">Launch Date</label>
            <input
              type="date"
              id="launchDate"
              name="launchDate"
              value={product.launchDate}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="raterInviteDate">Rater Invite Date</label>
            <input
              type="date"
              id="raterInviteDate"
              name="raterInviteDate"
              value={product.raterInviteDate}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="surveyEndDate">Survey End Date</label>
            <input
              type="date"
              id="surveyEndDate"
              name="surveyEndDate"
              value={product.surveyEndDate}
              onChange={handleChange}
            />
          </div>
          <div className="form-group full-submit">
            <button type="submit">{isEditMode ? 'Update' : 'Create'}</button>
          </div>
        </form>
      </div>
      <div className="product-list-container">
        <h2>Product List</h2>
        <table className="product-list">
          <thead>
            <tr>
              <th>Coach Name</th>
              <th>Product Name</th>
              <th>Participants</th>
              <th>Leaders</th>
              <th>Rater Invite Date</th>
              <th>Survey End Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((p) => (
              <tr key={p._id}>
                <td>{p.coachName || 'N/A'}</td>
                <td>{p.productName || 'N/A'}</td>
                <td>{p.participantCount || 0}</td>
                <td>{p.leaderCount || 0}</td>
                <td>
                  {p.raterInviteDate
                    ? new Date(p.raterInviteDate).toLocaleDateString()
                    : 'N/A'}
                </td>
                <td>
                  {p.surveyEndDate
                    ? new Date(p.surveyEndDate).toLocaleDateString()
                    : 'N/A'}
                </td>
                <td>
                  <div className="action-buttons">
                    <button onClick={() => handleEdit(p)}>Edit</button>
                    <button onClick={() => handleRegStatus(p)}>Reg Status</button>
                    <button onClick={() => handleResponseStatus(p)}>Response Status</button>
                    {p.participantCount === 0 && p.leaderCount === 0 ? (
                      <div
                        className="delete-icon-container"
                        title="Delete Product"
                        onClick={() => handleDeleteProduct(p.productName)}
                      >
                        🗑️
                      </div>
                    ) : (
                      <span
                        className="disabled-delete-icon"
                        title="Cannot delete product with associated participants or leaders"
                      >
                        🔒
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductPage;
